import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Dialog, ThemeProvider, Tooltip } from "@mui/material";

import "./TopicComment.scss";
import Divider from "../Divider/Divider";
import axios from "../../utils/Axios";
import { UidGenerator } from "../../utils/UidGenerator";
import { LikeIcon } from "../Icons/Icons";
import { Context } from "../../utils/Context";
import { AnonContext } from "../AnonymousTabs/State";
import { PubContext } from "../PublicTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import { ForumContext } from "../Forum/State";
import { commentState, commentReducer } from "./State";
import { FetchComments } from "../../utils/Effects";
import { FormatTime } from "../../utils/TimeFormatter";
import { commentDialogTheme } from "../../utils/MuiThemes";
import { TopicTooltipTheme } from "../../utils/MuiThemes";
import { RenderContent } from "../../utils/RenderContent";
import Moment from "react-moment";
import { Circle } from "@mui/icons-material";

function TopicComment({
  comntId,
  pChild,
  pDispatch,
  tpDispatch,
  tDispatch,
  tPage,
  newSibling,
  showMoreAgain,
}) {
  const { t } = useTranslation(["components, extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useContext(Context);
  const [remaining, setRemaining] = useState(false);
  const { anonDispatch } = useContext(AnonContext);
  const { pubDispatch } = useContext(PubContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);
  const { forumDispatch } = useContext(ForumContext);
  const [lState, lDispatch] = useReducer(commentReducer, commentState);

  const {
    loadMore,
    remain,
    showMore,
    commentData,
    open,
    showModal,
    isCommentDeleted,
  } = lState;

  const { userInfo } = state;
  const loadMoreRef = useRef(null);
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const handleClickOpen = (id) => {
    lDispatch({ type: "SHOW_MODAL", payload: id });
    lDispatch({ type: "OPEN_MODAL" });
  };

  const handleClose = () => {
    lDispatch({ type: "CLOSE_MODAL" });
  };

  //Fetch data for topic comments
  FetchComments(lDispatch, comntId, loadMore, isCommentDeleted, tpDispatch);

  const handleDeleteComment = async () => {
    const { data } = await axios.post(
      "/delc",
      {
        id: showModal,
        type: "c",
      },
      config
    );
    if (data?.status === true) {
      lDispatch({ type: "DELETE_COMMENT", payload: showModal });
      if (typeof pDispatch === "function") {
        pDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof tpDispatch === "function") {
        tpDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof anonDispatch === "function") {
        anonDispatch({
          type: "UPDATE_COMMENT",
          payload: {
            pId: comntId,
            newCount: data?.del_count,
          },
        });
      }
      if (typeof pubDispatch === "function") {
        pubDispatch({
          type: "UPDATE_COMMENT",
          payload: {
            pId: comntId,
            newCount: data?.del_count,
          },
        });
      }
      if (typeof priDispatch === "function") {
        priDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof perDispatch === "function") {
        perDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof fgDispatch === "function") {
        fgDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof lkDispatch === "function") {
        lkDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof svDispatch === "function") {
        svDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof forumDispatch === "function") {
        forumDispatch({
          type: "UPDATE_COMMENT",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }
      if (typeof tDispatch === "function") {
        tDispatch({
          type: "UPDATE_TOPIC_COMMENT_NO",
          payload: { pId: comntId, newCount: data?.del_count },
        });
      }

      enqueueSnackbar(data.msg, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar("Comment has been deleted", { variant: "error" });
      lDispatch({ type: "DELETE_COMMENT", payload: showModal });
      handleClose();
    }
  };

  const handleShowMore = (id) => {
    if (showMore === null) {
      lDispatch({ type: "SHOW_MORE", payload: id });
    } else {
      lDispatch({ type: "SHOW_MORE", payload: null });
    }
  };

  const handleLoad = (id) => {
    if (comntId === id) {
      lDispatch({ type: "LOAD_MORE", payload: showMoreAgain ? 1 : 3 });
    }
  };

  useEffect(() => {
    if (newSibling !== null) {
      lDispatch({
        type: "UPDATE_COMMENT_DATA",
        payload: { newSibling: newSibling, parentId: comntId },
      });
    }
  }, [newSibling]);

  useEffect(() => {
    if (showMoreAgain) {
      setRemaining(true);
    } else {
      setRemaining(remain);
    }
  }, [showMoreAgain, remain]);

  return (
    <div style={{ marginTop: "15px" }}>
      {commentData?.map((comment) => {
        return (
          <>
            <Divider width="100%" />
            <div className="tcContainer" key={comment?.comnt_id}>
              <div className="tcComment">
                <span className="tcIdentifier">
                  <ThemeProvider theme={TopicTooltipTheme}>
                    <Tooltip title={t("components:identifierTooltip")}>
                      <span>{UidGenerator(comment?.time, comment.usr_id)}</span>
                    </Tooltip>
                  </ThemeProvider>
                </span>
                <span className="tcText">
                  {showMore === comment.comnt_id
                    ? RenderContent(comment?.content?.split(/\r?\n/))
                    : RenderContent(
                        comment?.content?.substring(0, 200)?.split(/\r?\n/)
                      )}
                  {comment.content?.length > 200 && (
                    <button
                      onClick={() => {
                        handleShowMore(comment.comnt_id);
                      }}
                    >
                      {showMore === comment.comnt_id ? (
                        <span style={{ marginLeft: "2px" }}>
                          {t("extras:btnSL")}
                        </span>
                      ) : (
                        t("extras:btnSM")
                      )}
                    </button>
                  )}
                </span>
              </div>

              <div className="tcSecondBlock">
                <div className="tcBadgeIcon">
                  <LikeIcon
                    postId={comment?.comnt_id}
                    like={comment?.likes}
                    lkSt={comment?.like_st}
                  />
                </div>

                {tPage === true ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <div className="small">
                      <Moment format="DD/MM/YYYY">{comment?.time}</Moment>
                    </div>{" "}
                    <Circle className="dot" />
                    <div className="small">
                      <Moment format="HH:mm">{comment?.time}</Moment>
                    </div>
                  </div>
                ) : (
                  <div className="small">{FormatTime(comment?.time)}</div>
                )}
                {comment?.usr_id === userInfo?.idd && (
                  <img
                    src="/assets/images/recycle.svg"
                    alt=""
                    className="tcIcon"
                    onClick={() => handleClickOpen(comment?.comnt_id)}
                  />
                )}
                <ThemeProvider theme={commentDialogTheme}>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    PaperProps={{
                      style: {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <div className="tcDialogContainer">
                      <h4>{t("components:delComment")}</h4>
                      <p>{t("components:sureDeleteComment")}</p>
                      <p> {t("components:noUndo")}</p>
                      <div className="btn">
                        <button onClick={handleClose} className="btnCancel">
                          {t("extras:btnCancel")}
                        </button>
                        <button
                          onClick={handleDeleteComment}
                          className="btnExecute"
                        >
                          {t("extras:btnDelete")}
                        </button>
                      </div>
                    </div>
                  </Dialog>
                </ThemeProvider>
              </div>
            </div>
          </>
        );
      })}
      {pChild > 3 && remaining && (
        <div ref={loadMoreRef} className="tcMoreComments">
          <button onClick={() => handleLoad(comntId)}>
            {t("components:moreComments")}
          </button>
        </div>
      )}
    </div>
  );
}

export default TopicComment;
