import moment from "moment";

//Time formater
export const FormatTime = (time) => {
  let timeArray = moment(time).fromNow().split(" ");

  if (timeArray[0] === "a" || timeArray[0] === "an") {
    if (timeArray[1] === "year") {
      return "1y";
    } else if (timeArray[1] === "month") {
      return "1mo";
    } else if (timeArray[1] === "day") {
      return "1d";
    } else if (timeArray[1] === "hour" || timeArray[2] === "hour") {
      return "1h";
    }
    if (timeArray[1] === "minute" || timeArray[2] === "minute") {
      return "1m";
    }
    if (timeArray[1] === "seconds" || timeArray[2] === "seconds") {
      return moment(time).fromNow();
    }
  }
  if (timeArray[1] === "minutes" && timeArray[0] !== "a") {
    return timeArray[0] + "m";
  } else if (timeArray[1] === "hours") {
    return timeArray[0] + "h";
  } else if (timeArray[1] === "days") {
    return timeArray[0] + "d";
  } else if (timeArray[1] === "months") {
    return timeArray[0] + "mo";
  } else if (timeArray[1] === "years") {
    return timeArray[0] + "y";
  } else {
    return moment(time).fromNow();
  }
};

export const canEditText = (time) => {
  const postTime = new Date(time).getTime();
  const currentTime = new Date().getTime();
  return currentTime - postTime <= 900000;
};

export const zeroPad = (num) => {
  if (num <= 9) {
    return `0${num}`;
  } else {
    return num;
  }
};

export const formatChildren = (number) => {
  if (number >= 1000000) {
      return (number / 1000000) + "M";
  } else if (number >= 1000) {
      return (number / 1000) + "k";
  } else {
      return number;
  }
}

export const addTimeToTimestamp=(timestamp, timeToAdd)=> {
  // Parse the initial timestamp into a Date object
  let date = new Date(timestamp);

  // Parse days, hours, minutes, and seconds from timeToAdd string
  const daysMatch = timeToAdd.match(/(\d+)\s*days?/);
  const timeMatch = timeToAdd.match(/(\d{1,2}):(\d{1,2}):(\d{1,2})/);

  // Extract days, hours, minutes, and seconds
  const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
  const hours = timeMatch ? parseInt(timeMatch[1], 10) : 0;
  const minutes = timeMatch ? parseInt(timeMatch[2], 10) : 0;
  const seconds = timeMatch ? parseInt(timeMatch[3], 10) : 0;

  // Add days, hours, minutes, and seconds to the date
  date.setDate(date.getDate() + days);
  date.setHours(date.getHours() + hours);
  date.setMinutes(date.getMinutes() + minutes);
  date.setSeconds(date.getSeconds() + seconds);

  // Return the updated timestamp in ISO format
  return date.toISOString();
}

// Example usage:
const timestamp = "Tue, 05 Nov 2024 14:39:57 GMT";
const timeToAdd = "4 days, 10:10:00";
console.log(addTimeToTimestamp(timestamp, timeToAdd));


export const getTimeDifference=(futureTimestamp)=> {
  const now = new Date();
  const futureDate = new Date(futureTimestamp);

  // Calculate the difference in milliseconds
  let diff = futureDate - now;

  // If the time is in the past, return "Voting ended"
  if (diff <= 0) {
      return "Voting ended";
  }

  // Calculate days, hours, and minutes
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);
  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);
  const minutes = Math.floor(diff / (1000 * 60));

  // Determine the most relevant time component to return
  if (days >= 1) {
      return `${days} day${days > 1 ? 's' : ''} left`;
  } else if (hours >= 1) {
      return `${hours} hour${hours > 1 ? 's' : ''} left`;
  } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''} left`;
  }
}
