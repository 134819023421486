import {
  useContext,
  useRef,
  useReducer,
  useEffect,
  useState,
  useCallback,
} from "react";

import { useSnackbar } from "notistack";
import { FlagOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import comment from "../../publicImages/comment.svg";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  ThemeProvider,
} from "@mui/material";

import "./TabPage.scss";
import TabSettings from "../TabSettings/TabSettings";
import ErrorPage from "../ErrorPage/ErrorPage";
import axios from "../../utils/Axios";
import TabPageTopicComment from "../TabPageTopicComment/TabPageTopicComent";
import Divider from "../Divider/Divider";
import { TooltipTheme } from "../../utils/MuiThemes";
import { Context } from "../../utils/Context";
import { reducer, initialState } from "./State";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "../../utils/Effects";
import Loading from "../Loading/Loading";
import Layout from "../Layout/Layout";
import HomeLeftBox, { HomeLeftBoxTop } from "../HomeLeftBox/HomeLeftBox";
import Footer from "../Footer/Footer";
import RightComponent from "../RightComponent/RightComponent";
import TabPage from "./TabPage";
import NewPostBox from "../NewPostBox/NewPostBox";

function TabPagePublic() {
  const { enqueueSnackbar } = useSnackbar();
  const modalRef = useRef();
  const checkRef = useRef();
  const tabContainerRef = useRef();
  const { state, dispatch } = useContext(Context);
  const [position, setPosition] = useState({});
  const { t } = useTranslation(["components", "extras"]);
  const navigate = useNavigate();
  const { currentTabId } = useParams();
  const {
    apiLoading,
    loadText,
    modTab,
    verifyStatus,
    userInfo,
    currentTabName,
    previousPage,
  } = state;
  const [lState, lDispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const {
    load,
    descData,
    showModal,
    followStatus,
    showSettings,
    showSetTopic,
    showTopic,
    topicData,
    reload,
    errorPage,
    isTabDirector,
  } = lState;

  //Show loading page
  LoadingPage(setLoading);

  //fetchTabDataReload

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  //fetchTabData
  const fetchTabData = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    try {
      dispatch({ type: "START_LOADING" });
      const { data } = await axios.get(
        `/u/tt?t=${currentTabId}&cntr=${load}`,
        config
      );
      if (data?.status === false) {
        lDispatch({ type: "SET_ERROR_PAGE" });
      } else {
        lDispatch({ type: "SET_TOPIC_DATA", payload: data?.temp_array });
        dispatch({ type: "STOP_LOADING" });
        if (data?.temp_array?.length === 0) {
          dispatch({
            type: "NO_MORE_TOPIC",
            payload: t("components:noMoreTopic"),
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //fetchDescriptionData

  const fetchDescData = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };

    try {
      const { data } = await axios.get(`/u/t?t=${currentTabId}`, config);
      lDispatch({ type: "SET_DESC_DATA", payload: data });
      dispatch({
        type: "SET_CURRENT_TAB_ID",
        payload: data?.tab_desc?.tab_id,
      });
      dispatch({
        type: "SET_CURRENT_TAB_NAME",
        payload: data?.tab_desc?.name,
      });

      if (data?.fllwng === true) {
        lDispatch({
          type: "SET_FOLLOW_STATUS",
          payload: "unfollow",
        });
      } else {
        lDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTabDirectorStatus = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const { data } = await axios.get(`/dxr?tab=${currentTabName}`, config);
    lDispatch({ type: "SET_TAB_DIRECTOR", payload: data?.status });
  };

  const checkContainerPosition = useCallback(() => {
    setPosition(checkRef.current.getBoundingClientRect());
  }, []);

  useEffect(() => {
    const div = tabContainerRef.current;
    div.addEventListener("scroll", checkContainerPosition);
  }, [checkContainerPosition]);

  useEffect(() => {
    lDispatch({ type: "REFRESH" });
  }, [currentTabId]);

  useEffect(() => {
    fetchDescData();
    fetchTabDirectorStatus();
  }, [currentTabId, modTab, verifyStatus]);

  useEffect(() => {
    fetchTabData();
  }, [load, currentTabId]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showModal &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        lDispatch({ type: "HIDE_MODAL" });
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal, modalRef]);

  const loadMore = () => {
    lDispatch({ type: "SET_LOAD" });
  };

  const followFunc = async (tabName) => {
    const { data } = await axios.get(`/follow?tab=${tabName}`, config);
    if (data.msg === "Now following.") {
      dispatch({ type: "FOLLOWING", payload: data.msg });
      lDispatch({
        type: "SET_FOLLOW_STATUS",
        payload: "unfollow",
      });
    } else if (data.msg === "Unfollowed.") {
      dispatch({ type: "NOT_FOLLOWING", payload: data.msg });
      lDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
    }

    enqueueSnackbar(data?.msg, {
      variant: data?.status === true ? "success" : "error",
    });
  };

  //Verify Function

  const verifyFunc = async () => {
    const { data } = await axios.get(
      `/ttv?email=${userInfo?.email}&tab=${currentTabId}`,
      config
    );
    enqueueSnackbar(data.msg, {
      variant: data?.status !== false ? "success" : "error",
    });

    if (data.status === true) {
      dispatch({ type: "VERIFIED" });
    }
  };

  //Resign Function

  const resignFunc = async () => {
    const { data } = await axios.get(`/resign?tabId=${currentTabId}`, config);
    if (data.status === true) {
      dispatch({ type: "VERIFIED" });
      enqueueSnackbar("You've left the Tab", { variant: "success" });
    }
    lDispatch({ type: "HIDE_MODAL" });
    dispatch({ type: "SHOW_TAB_PAGE", payload: null });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
  };

  const handleShowTopic = (id, title) => {
    if (splitLocation[1] === "public") {
      enqueueSnackbar("No access to topic.", {
        variant: "error",
      });
      return;
    }
    if (title === "...private topic...") {
      enqueueSnackbar("This content is private to Tab's participants", {
        variant: "error",
      });
      return;
    }
    if (showTopic.includes(id)) {
      lDispatch({ type: "REMOVE_TOPIC", payload: id });
    } else {
      lDispatch({ type: "INCLUDE_TOPIC", payload: id });
    }
  };

  const handleCloseModal = () => {
    lDispatch({ type: "HIDE_MODAL" });
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const handleGoBack = () => {
    previousPage === "forum" ? navigate(-1) : navigate("/");
  };

  useEffect(() => {
    if (currentTabId) {
      navigate(`/tabs/${currentTabId}`);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout
          title={`${
            descData?.tab_desc?.title === undefined
              ? ""
              : descData?.tab_desc?.title
          } (@${
            descData?.tab_desc?.name === undefined
              ? ""
              : descData?.tab_desc?.name
          }) on TruTab`}
          description="Trutab&aposs Public Tab"
        >
          <div className="left">
            <div className="withPadding">
              <HomeLeftBoxTop />
              <HomeLeftBox />
            </div>

            <div
              className="footer"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Footer />
            </div>
          </div>

          <div className="middle">
            <div className="tabContainer" ref={tabContainerRef}>
              <>
                {errorPage ? (
                  <ErrorPage />
                ) : descData?.usr_id === undefined ? (
                  <div className="tpReload">
                    <div>
                      <CircularProgress color="inherit" size={25} />
                    </div>
                  </div>
                ) : (
                  <>
                    {(currentTabName !== null || currentTabId !== null) && (
                      <TabPage />
                    )}
                    {reload ? (
                      <div className="tpReload">
                        <div>
                          {reload && (
                            <CircularProgress color="inherit" size={25} />
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="tpNavCont">
                          <nav
                            className="tpNav"
                            style={{ zIndex: currentTabName !== null && 0 }}
                          >
                            <IconButton
                              className="clickable"
                              style={{
                                display:
                                  position?.top > 58 ||
                                  position?.top === undefined
                                    ? "flex"
                                    : "none",
                              }}
                              onClick={() => handleGoBack()}
                            >
                              <img
                                src="/assets/images/arrowBack.png"
                                alt=""
                                className="tpNavArrowBack"
                              />
                            </IconButton>
                            <div
                              // onClick={reloadFunc}
                              className="tpNavTitle"
                              style={{
                                display:
                                  position?.top > 58 ||
                                  position?.top === undefined
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {descData?.tab_desc?.title}
                            </div>
                          </nav>
                        </div>

                        {descData?.tab_desc?.img !== null && (
                          <div className="tpBodyImg">
                            {descData?.tab_desc?.img === "" ? (
                              <img
                                src="/assets/images/newTabl.svg"
                                alt="attachment"
                                className="defaultImg"
                              />
                            ) : (
                              <img
                                src={`https://storage.googleapis.com/trutab/imgs/${descData?.tab_desc?.img}`}
                                alt=""
                                className="tabImg"
                              />
                            )}
                          </div>
                        )}

                        <div className="tpNameMemDirNoImg">
                          <div className="tpcurrentTabIdNoImg">
                            @{descData?.tab_desc?.name}
                          </div>
                          {descData?.membs_dirs.length === 0 ? (
                            ""
                          ) : (
                            <div className="tpMemberDirNoImg">
                              {descData?.membs_dirs[0]}{" "}
                              {descData?.membs_dirs[0] > 1
                                ? t("components:Members")
                                : t("components:Member")}{" "}
                              | {descData?.membs_dirs[1]}{" "}
                              {descData?.membs_dirs[1] > 1
                                ? t("components:Directors")
                                : t("components:Director")}
                            </div>
                          )}
                        </div>

                        <div className="tpDescription">
                          {descData?.tab_desc?.desc}
                        </div>
                        <div
                          className={
                            position?.top < 58
                              ? "tpLeaveTopicCommentChangePosition"
                              : "tpLeaveTopicComment"
                          }
                          ref={checkRef}
                        >
                          <IconButton
                            className="clickable"
                            style={{
                              display: position?.top < 58 ? "flex" : "none",
                            }}
                            onClick={() => handleGoBack()}
                          >
                            <img
                              src="/assets/images/arrowBack.png"
                              alt=""
                              className="tpNavArrowBack"
                            />
                          </IconButton>
                          <div
                            style={{
                              display: position?.top < 58 ? "flex" : "none",
                              alignItems: "center",
                            }}
                          >
                            <div
                              // onClick={reloadFunc}
                              className="tpNavTitleChangePosition"
                            >
                              {descData?.tab_desc?.title}
                            </div>
                          </div>
                          {descData?.tabs_vrfd.includes(
                            descData?.tab_desc?.name
                          ) ? (
                            <div
                              onClick={() => {
                                lDispatch({ type: "SHOW_MODAL" });
                              }}
                              className="tpLeave"
                              style={{
                                display:
                                  position?.top > 58 ||
                                  position?.top === undefined
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {(position?.top > 58 ||
                                position?.top === undefined) && (
                                <>
                                  <Tooltip title="Leave Participation" arrow>
                                    <span className="btn_participation">
                                      <img
                                        src="/assets/images/leave.svg"
                                        alt="leave"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      {t("extras:btnLeave")}
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              onClick={verifyFunc}
                              className="tpLeave"
                              style={{
                                display:
                                  position?.top > 49 ||
                                  position?.top === undefined
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {(position?.top > 58 ||
                                position?.top === undefined) && (
                                <>
                                  <Tooltip title="Verify Participation" arrow>
                                    <span className="btn_participation">
                                      <img
                                        src="/assets/images/leave.svg"
                                        alt="leave"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      {t("extras:btnGO")}
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          )}
                          <ThemeProvider theme={TooltipTheme}>
                            <div className="tpSettingTopic">
                              {descData?.tabs_vrfd.includes(
                                descData?.tab_desc?.name
                              ) ? (
                                <div
                                  onClick={() => {
                                    lDispatch({ type: "SHOW_MODAL" });
                                  }}
                                  className="tpLeave"
                                  style={{
                                    display:
                                      position?.top < 49 ? "flex" : "none",
                                  }}
                                >
                                  <img
                                    src={`/assets/images/${
                                      position?.top < 49
                                        ? "leaveWhite"
                                        : "leave"
                                    }.svg`}
                                    alt="leave"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                  {(position?.top > 58 ||
                                    position?.top === undefined) &&
                                    t("extras:btnLeave")}
                                </div>
                              ) : (
                                <div
                                  onClick={verifyFunc}
                                  className="tpLeave"
                                  style={{
                                    display:
                                      position?.top < 50 ? "flex" : "none",
                                  }}
                                >
                                  <img
                                    src={`/assets/images/${
                                      position?.top < 49
                                        ? "leaveWhite"
                                        : "leave"
                                    }.svg`}
                                    alt="leave"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                  {(position?.top > 58 ||
                                    position?.top === undefined) &&
                                    t("extras:btnGO")}
                                </div>
                              )}
                              {descData?.lgdUsrId ===
                                descData?.tab_desc?.usr_id && (
                                <Tooltip
                                  title={t("components:tbSettings")}
                                  arrow
                                >
                                  <img
                                    src={`/assets/images/${
                                      position?.top < 49
                                        ? "settingWhite"
                                        : "setting"
                                    }.svg`}
                                    alt="tab settings"
                                    onClick={() => {
                                      lDispatch({ type: "SET_SHOW_SETTINGS" });
                                    }}
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </Tooltip>
                              )}
                              {descData?.tabs_vrfd
                                .map((element) => element.toLowerCase())
                                ?.includes(
                                  descData?.tab_desc?.name?.toLowerCase()
                                ) ? (
                                <Tooltip title={t("components:newTopic")} arrow>
                                  <img
                                    style={{ width: "20px", height: "20px" }}
                                    src={`/assets/images/${
                                      position?.top < 49
                                        ? "topicWhite"
                                        : "topic"
                                    }.svg`}
                                    alt="topic"
                                    onClick={() =>
                                      lDispatch({ type: "SET_SHOW_TOPIC" })
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </ThemeProvider>
                        </div>

                        <div className="tpDivider">
                          <Divider width="calc(100% - 16px)" color="#908fc6" />
                        </div>

                        <div className="tpBody">
                          {showSetTopic && (
                            <NewPostBox
                              tabTitle={descData?.tab_desc?.title}
                              lDispatch={lDispatch}
                              topicTable={descData?.tab_desc?.name}
                              isTabDirector={isTabDirector}
                            />
                          )}
                          {showSettings && (
                            <div className="tpSettings">
                              <TabSettings lDispatch={lDispatch} />
                            </div>
                          )}
                          <div className="tpTopicNum">
                            <div>
                              {descData?.total === 0 ? "" : descData?.total}{" "}
                              {descData?.total < 1
                                ? t("components:noTopic")
                                : descData?.total === 1
                                ? t("components:topic")
                                : t("components:topics")}
                            </div>
                            {descData?.membs_dirs.length === 0 ? (
                              <button
                                onClick={() =>
                                  followFunc(descData?.tab_desc?.name)
                                }
                              >
                                {followStatus === "unfollow"
                                  ? t("components:unfollow")
                                  : t("components:follow")}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {topicData?.map((topic) => {
                          return (
                            <div className="tpTopic" key={topic?.comnt_id}>
                              <div
                                className="tpTitle"
                                onClick={() =>
                                  handleShowTopic(
                                    topic?.comnt_id,
                                    topic?.content
                                  )
                                }
                              >
                                <span className="tpTitleHead">
                                  {topic?.content?.slice(0, 215)}
                                  {topic?.content?.length > 215 && "..."}
                                </span>
                                {topic?.settings[1] !== true &&
                                  descData?.tabs_vrfd.includes(
                                    descData?.tab_desc?.name
                                  ) && <FlagOutlined className="flag" />}
                                {topic?.children < 1 ? (
                                  ""
                                ) : (
                                  <div className="tpCommentNum">
                                    <span>{topic?.children}</span>

                                    <img
                                      src={comment}
                                      alt=""
                                      className="tpCommentIcon"
                                    />
                                  </div>
                                )}
                              </div>
                              {showTopic?.includes(topic?.comnt_id) && (
                                <div className="tpCmt">
                                  <TabPageTopicComment
                                    topicLId={topic?.comnt_id}
                                    sDispatch={lDispatch}
                                    tabTitle={descData?.tab_desc?.title}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                        <div className="tpLoadMore">
                          <div>
                            {apiLoading && (
                              <CircularProgress color="inherit" size={25} />
                            )}
                          </div>
                          {descData?.total !== 0 && (
                            <button onClick={loadMore}>
                              {loadText === "Load more topics"
                                ? t("components:loadMoreTopics")
                                : loadText === "Loading"
                                ? t("components:loading")
                                : t("components:noMoreTopics")}
                            </button>
                          )}
                        </div>

                        <Dialog
                          open={showModal}
                          onClose={handleCloseModal}
                          maxWidth="xs"
                        >
                          <div
                            style={{ padding: "30px", background: "#f8f5fe" }}
                          >
                            {" "}
                            <DialogTitle id="responsive-dialog-title">
                              <Typography>
                                {t("components:leaveTab")}
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                <Typography>
                                  {t("components:sureLeaveTab")}
                                </Typography>
                                <Typography>
                                  {t("components:tabLeaveWarn")}
                                </Typography>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <div className="tpBtnCont">
                                <Button onClick={resignFunc}>
                                  {t("components:leave")}
                                </Button>
                                <Button
                                  onClick={() => {
                                    lDispatch({ type: "HIDE_MODAL" });
                                  }}
                                >
                                  {t("components:noLeave")}
                                </Button>
                              </div>
                            </DialogActions>
                            <DialogContentText>
                              <Typography>
                                {t("components:unfollowToStop")}
                              </Typography>
                              <Typography>
                                {t("components:comeInAgain")}
                              </Typography>
                            </DialogContentText>
                          </div>
                        </Dialog>
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>

          <div className="right">
            <RightComponent />
          </div>
        </Layout>
      )}
    </>
  );
}

export default TabPagePublic;
