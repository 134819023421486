import { useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import "./TabsYouPaticipate.scss";
import SeeButtons from "../SeeButtons/SeeButtons";
import Divider from "../Divider/Divider";
import { Context } from "../../utils/Context";
import { GetVerified } from "../../utils/Effects";
import TabsHeadCard from "../TabsHeadCard/TabsHeadCard";

function TabsYouPaticipate() {
  //Get verified Tabs
  GetVerified();

  const [moreTab, setMoreTab] = useState(false);
  const [viewLimit, setViewLimit] = useState(4);

  const { t } = useTranslation(["components", "extras"]);

  const { state, dispatch } = useContext(Context);
  const { tabsLoading, verified, tabsHeadCard } = state;

  const viewAll = () => {
    if (!moreTab) {
      setViewLimit(verified?.length);
      setMoreTab(!moreTab);
    } else {
      setViewLimit(4);
      setMoreTab(!moreTab);
    }
  };

  //Show Tab Page
  const showTabPage = (participated) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: participated?.name });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: participated?.tab_id });
    dispatch({
      type: "ON_PAGE",
      payload: "tab",
    });
  };

  return (
    <div className="ypParticipated">
      <TabsHeadCard
        name={t("components:tabsYouPaticipated")}
        tag="participate"
        click={() =>
          dispatch({ type: "TABS_HEAD_CARD", payload: "participate" })
        }
        icon={<img src="/assets/images/participate.svg" alt="participate" />}
      />
      <section
        className={
          tabsHeadCard?.includes("participate")
            ? "ypTabsHead"
            : "ypTabsHead ypTabsHeadCol"
        }
      >
        <div className="ypSubGrid">
          {tabsLoading ? (
            <p className="ypLoading">{t("components:loading")}</p>
          ) : verified?.length === 0 ? (
            t("components:noParticipate")
          ) : (
            verified?.slice(0, viewLimit).map((paticipated, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    showTabPage(paticipated);
                  }}
                  className="ypTab"
                >
                  <div className="ypText">
                    {" "}
                    <span className="unique">
                      @
                      {paticipated?.name?.length < 20
                        ? paticipated?.name
                        : paticipated?.name?.substring(0, 20) + "..."}
                    </span>
                    <span className="nonUnique">
                      {paticipated?.title?.length < 20
                        ? paticipated?.title
                        : paticipated?.title?.substring(0, 20) + "..."}
                    </span>
                  </div>
                  <div className="ypIcon">
                    {paticipated?.forum_start !== null && (
                      <img
                        src="/assets/images/time.svg"
                        alt=""
                        className="forumActive"
                      />
                    )}
                    {paticipated?.is_director === true && (
                      <span className="ypDir">
                        <img src="/assets/images/director.svg" alt="" />
                      </span>
                    )}
                    {paticipated?.any_flag === true && (
                      <img
                        src="/assets/images/flagTab.svg"
                        className="flag"
                        alt=""
                      />
                    )}
                  </div>
                </button>
              );
            })
          )}
        </div>
        <div className="ypButton">
          {verified?.length > 3 && (
            <button onClick={viewAll}>
              {moreTab ? t("extras:btnVL") : t("extras:btnVA")}
            </button>
          )}
          <div className="ypPadding"></div>
          <button>
            {t("components:pTotal")}: {verified?.length}
          </button>
        </div>
        <div style={{ paddingBottom: "5px" }}></div>
      </section>
      <div className="ypTabsNameDivider">
        <Divider />
      </div>
      <SeeButtons flagged="flag" personal="personal" />
      <div className="ypTabsNameDividerT">
        <Divider />
      </div>
    </div>
  );
}

export default TabsYouPaticipate;
