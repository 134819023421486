import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import "./TabsYouControl.scss";
import SeeButtons from "../SeeButtons/SeeButtons";
import Divider from "../Divider/Divider";
import { Context } from "../../utils/Context";
import { ThemeProvider, Tooltip } from "@mui/material";
import { YCTooltipTheme } from "../../utils/MuiThemes";
import { GetCreated } from "../../utils/Effects";
import TabsHeadCard from "../TabsHeadCard/TabsHeadCard";

function TabsYouControl() {
  //Get controlled Tabs
  GetCreated();
  // HideTabPage();
  const { t } = useTranslation(["components, extras"]);

  const { state, dispatch } = useContext(Context);
  const { userTabs, tabsLoading, created, tabsHeadCard } = state;
  const [active, setActive] = useState(false);

  useEffect(() => {
    const currentDate = new Date().getTime();
    const expiryDate = new Date(userTabs?.next_sub).getTime();
    if (expiryDate <= currentDate) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, []);

  //Show Tab Page
  const showTabPage = (control) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: control?.name });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: control?.tab_id });
    dispatch({
      type: "ON_PAGE",
      payload: "tab",
    });
  };

  return (
    <div className="ycContainer">
      <TabsHeadCard
        name={t("components:tabsYouControl")}
        tag="control"
        click={() => dispatch({ type: "TABS_HEAD_CARD", payload: "control" })}
        icon={<img src="/assets/images/controlSettings.svg" alt="setting" />}
      />
      <section
        className={
          tabsHeadCard?.includes("control")
            ? "ycTabsHead"
            : "ycTabsHead ypTabsHeadCol"
        }
      >
        {tabsLoading ? (
          <p className="ycLoading">{t("components:loading")}</p>
        ) : created?.length === 0 ? (
          <p className="ycNoTab">{t("components:noControl")}</p>
        ) : (
          created?.map((control, index) => {
            return (
              <button
                onClick={() => {
                  showTabPage(control);
                }}
                className="ycTab"
                key={index}
              >
                <div className="uniqueCont">
                  <span className="unique">
                    @
                    {control?.name?.length < 20
                      ? control?.name
                      : control?.name?.substring(0, 20) + "..."}
                  </span>
                  <span>
                    {control?.status !== true && (
                      <ThemeProvider theme={YCTooltipTheme}>
                        <Tooltip title={t("components:restrictTooltip")}>
                          <img src="/assets/images/warning.svg" alt="" />
                        </Tooltip>
                      </ThemeProvider>
                    )}
                  </span>
                </div>
                <span className="nonUnique">
                  {control?.title?.length < 20
                    ? control?.title
                    : control?.title?.substring(0, 20) + "..."}
                </span>
              </button>
            );
          })
        )}
        <div style={{ paddingBottom: "5px" }}></div>
      </section>
      {userTabs?.next_sub !== (null || 0) && active === true && (
        <>
          <div className="ycDivider">
            <Divider />
          </div>
          <div className="ycSub">
            <img src="/assets/images/info.svg" alt="info" />
            {t("components:activeSub")} ({userTabs?.paid_aggreg} |{" "}
            {userTabs?.paid_dir}) {t("components:endsOn")}{" "}
            <Moment format="ddd, DD MMM YYYY HH:mm">
              {userTabs?.next_sub}
            </Moment>{" "}
            GMT
          </div>
          <div className="ycSubLink">
            <Link to={"/home/subscriptions"}>{t("components:viewSub")}</Link>
          </div>
        </>
      )}

      <div className="ycTabsHeadDividerB">
        <Divider />
      </div>
      <SeeButtons addNew="addNew" />
      <div className="ycTabsHeadDivider">
        <Divider />
      </div>
      <TabsHeadCard
        name={t("components:tcqHeading")}
        tag="controlq"
        click={() => dispatch({ type: "TABS_HEAD_CARD", payload: "controlq" })}
      />
      <section
        className={
          tabsHeadCard?.includes("controlq")
            ? "ycTabsHead"
            : "ycTabsHead ypTabsHeadCol"
        }
      >
        <div className="ycQuota">
          <img src="/assets/images/info.svg" alt="info" />
          {t("components:quota")} ({t("components:members")} |{" "}
          {t("components:directors")})
        </div>
        <div className="ycQuota">
          {t("components:total")}:{" "}
          {userTabs?.aggregate !== undefined && userTabs?.aggregate["0"]} |{" "}
          {userTabs?.aggregate !== undefined && userTabs?.aggregate["1"]}
        </div>
        <div className="ycQuota">
          {t("components:available")}: {userTabs?.membs_quota} |{" "}
          {userTabs?.dirs_quota}
        </div>
        <div style={{ paddingBottom: "5px" }}></div>
        <SeeButtons upgrade="upgrade" />
        <div style={{ paddingBottom: "20px" }}></div>
      </section>

      <div>
        <Divider />
      </div>
    </div>
  );
}

export default TabsYouControl;
