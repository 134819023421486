import { useContext, useEffect } from "react";
import Footer from "../Footer/Footer";
import HomeLeftBox from "../HomeLeftBox/HomeLeftBox";
import Layout from "../Layout/Layout";
import RightComponent from "../RightComponent/RightComponent";
import "./ErrorPage.scss";

import { Link, useLocation } from "react-router-dom";
import { Context } from "../../utils/Context";

export default function ErrorPage() {
  const location = useLocation();

  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch({ type: "ERROR_PAGE_ON", payload: true });
  }, []);
  return (
    <>
      {location.pathname.split("/")[1] === "tabs" ||
      location.pathname.split("/")[1] === "topics" ||
      location.pathname.split("/")[1] === "posts" ||
      location.pathname.split("/")[1] === "forums" ? (
        <div className="errorPage">
          <img src="/assets/images/noContent.svg" alt="" />
          {location.pathname.split("/")[1] === "tabs" ? (
            <p>Could not find the Tab specified in that link</p>
          ) : location.pathname.split("/")[1] === "topics" ||
            location.pathname.split("/")[1] === "posts" ? (
            <p>The topic you requested is private!</p>
          ) : (
            <p>Page not found!</p>
          )}

          <Link to={"/"}>Go back home</Link>
        </div>
      ) : (
        <Layout>
          <div className="left">
            <div className="withPadding">
              <HomeLeftBox />
            </div>

            <div
              className="footer"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Footer />
            </div>
          </div>

          <div className="errorPage">
            <img src="/assets/images/noContent.svg" alt="" />
            <h1>Oops!</h1>
            <h4>Page not found!</h4>
            <div>
              <Link to={"/"}>Go back home</Link>
            </div>
          </div>

          <div className="right">
            <RightComponent />
          </div>
        </Layout>
      )}
    </>
  );
}
