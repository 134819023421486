import { createTheme } from "@mui/material/styles";

export const ProgressTheme = createTheme({
  palette: {
    primary: {
      main: "#572E91",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

export const TooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Source Sans Pro, sans-serif",
          fontSize: "0.72rem",
          color: "#fff",
          backgroundColor: "#555555",
          padding: "8px",
          boxShadow: "0px 0px 4px 1px #c4c4c4",
          maxWidth: "12rem",
          borderRadius: "6px",
        },
        arrow: {
          color: "#555555",
        },
      },
    },
  },
});

export const LayoutTooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "0.72rem",
          color: "#fff",
          backgroundColor: "#572e91",
          padding: "8px",
          borderRadius: "6px",
          boxShadow: "0px 0px 4px 1px #c4c4c4",
        },
        arrow: {
          color: "#572e91",
        },
      },
    },
  },
});

export const TopicTooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "0.72rem",
          backgroundColor: " #fff",
          color: "#444",
          boxShadow: "0px 0px 4px 1px #c4c4c4",
          border: "1px solid #c4c4c4",
          padding: "8px",
          borderRadius: "6px",
        },
      },
    },
  },
});

export const YCTooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "10px",
          fontFamily: "Open Sans, sans-serif",
          fontSize: "0.72rem",
          backgroundColor: " #fff",
          color: "#444",
          boxShadow: "0px 0px 4px 1px #c4c4c4",
          border: "1px solid #c4c4c4",
          borderRadius: "5px",
          width: "12rem",
        },
      },
    },
  },
});

export const homeDialogTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

export const tabsDialogTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.18)",
        },
      },
    },
  },
});

export const commentDialogTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      },
    },
  },
});

export const switchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          display: "flex",
          margin: "auto",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 12,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
        },
        thumb: {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          borderRadius: 10,
          transition: "width 200",
        },
        track: {
          borderRadius: 16,
          opacity: 1,
          backgroundColor: "#9b9b9b",
        },
        switchBase: {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: "#572e91",
            },
          },
          "& .Mui-checked": {
            transform: "translateX(9px)",
          },
        },
      },
    },
  },
});
