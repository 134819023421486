import { useContext, useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import "./SearchPost.scss";
import axios from "../../utils/Axios";
import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";
import Divider from "../Divider/Divider";
import { useLocation, useNavigate } from "react-router-dom";

function SearchPost() {
  const { t } = useTranslation(["components, extras"]);
  const inputRef = useRef(null);
  const navigate=useNavigate()
  const location=useLocation()

  const { dispatch, state } = useContext(Context);
  const { searchPostTab,currentTabName, searchPostTabResult, searchPostProcess, showSearchPostPanel } =
    state;
  const [debouncedSearchPostTab, setDebouncedSearchPostTab] =
    useState(searchPostTab);

  useEffect(() => {
    const timer = setTimeout(
      () =>
        dispatch({
          type: "SET_SEARCH_POST_TAB",
          payload: debouncedSearchPostTab,
        }),
      1000
    );
    return () => clearTimeout(timer);
  }, [debouncedSearchPostTab]);

  useEffect(() => {
    const handleFilter = async () => {
      const tkn = localStorage.getItem("tkn");

      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };

      if (searchPostTab?.length >= 3) {
        dispatch({ type: "CLEAR_POST_TAB_RESULT" });
        dispatch({ type: "START_SEARCH_POST" });
        try {
          const { data } = await axios.get(
            `/searchtab?t=${
              currentTabName
            }&q=${debouncedSearchPostTab}`,
            config
          );

          dispatch({ type: "STOP_SEARCH_POST" });
          dispatch({ type: "SEARCH_POST_TAB_RESULT", payload: data.res });
        } catch (error) {
          console.error("Error fetching search results:", error);
          dispatch({ type: "STOP_SEARCH_POST" });
        }
      } else {
        dispatch({ type: "SET_SEARCH_POST_TAB", payload: "" });
      }
    };
    handleFilter();
  }, [searchPostTab]);


  useEffect(() => {
    if (inputRef.current && showSearchPostPanel) {
      inputRef.current.focus();
    }
  }, [showSearchPostPanel]);

  

  const clearPostTab = () => {
    inputRef.current.value = "";
    dispatch({ type: "CLEAR_SEARCH_POST_TAB", payload: "" });
    dispatch({ type: "CLEAR_POST_TAB_RESULT" });
    setDebouncedSearchPostTab("");
  };
  const showTopicPage = (result) => {
    dispatch({
      type: "SHOW_TOPIC_PAGE",
      payload: {
        comntId: parseInt(result?.comnt_id),
        tabId: result?.tab_id,
      },
    });
    dispatch({
      type: "ON_PAGE",
      payload: "topic",
    });
  };
  
    const handleOnClickSearchPostTabResult = (result) => {
      dispatch({
        type: "SET_PREVIOUS_PAGE",
        payload: location.pathname.split("/")[1],
      });
      location.pathname.split("/")[1] === "tabs" ||
      location.pathname.split("/")[1] === "topics" ||
      location.pathname.split("/")[1] === "posts" ||
      location.pathname.split("/")[1] === "lists" ||
      location.pathname.split("/")[1] === "forums"
        ? navigate(`/posts/${result?.tab_id}`)
        : showTopicPage(result);
    };
  

  return (
<section style={{display:showSearchPostPanel?"block":"none"}}>
<div>
      <div className="tabSearchField">
     
        {searchPostTab?.length !== 0 ? (
          <CloseIcon
            className="sIcon"
            onClick={() => {
              clearPostTab();
              setDebouncedSearchPostTab("");
              if (inputRef.current) {
                inputRef.current.value = "";
              }
            }}
          />
        ) : (
          <img
            src="/assets/images/search.svg"
            alt="search-icon"
            className="sIcon"
          />
        )}
        <input
          type="text"
          placeholder="Search Posts"
          ref={inputRef}
          onChange={(e) => {
            setDebouncedSearchPostTab(e.target.value);
          }}
          defaultValue={debouncedSearchPostTab}
          
        />
      </div>
    </div>

{(searchPostTab?.length > 0 || searchPostProcess) && (
  <>

    <div>

      {searchPostProcess ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <CircularProgress size={20} />
        </div>
      ) : (
        searchPostTabResult &&
        searchPostProcess === false && (
          <p className="title">
            {searchPostTabResult.length === 0 && searchPostProcess === false
              ? "No result found"
              : "Search Results"}
          </p>
        )
      )}
    </div>
    <div className="searchResult" style={{opacity:searchPostTabResult?.length===0?0:1}} >
     
        {searchPostTabResult?.map((result) => {
          return (
            <div className="resultCont" key={result}>
              <button onClick={() => handleOnClickSearchPostTabResult(result)}>
                {result?.content?.length >= 40
                  ? result?.content?.slice(0, 40) + "..."
                  : result?.content}
                <br />
                
              </button>
              <Divider />
            </div>
          );
        })}
      </div>
   
  </>
)}
</section>
  );
}

export default SearchPost;
