import PollPage from "../PollPage/PollPage";

export const RenderPolls = ({ topicData }) => {
  const contentArray = topicData?.content?.split(/\r?\n/);

  return (
    <div>
      {contentArray.map((content, index) => {
        return (
          <div key={index} style={{ width: "100%" }}>
            <PollPage data={content} topicData={topicData} />
          </div>
        );
      })}
    </div>
  );
};
