import { useCallback, useContext, useRef, useState, useEffect } from "react";

import Topics from "../Topics/Topics";
import { ForumContext } from "./State";
import { GetForumData, GetVerified, useClickOut } from "../../utils/Effects";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../utils/Context";
import Loading from "../Loading/Loading";
import Layout from "../Layout/Layout";
import HomeLeftBox from "../HomeLeftBox/HomeLeftBox";
import Footer from "../Footer/Footer";
import ErrorPage from "../ErrorPage/ErrorPage";
import TabPage from "../TabPage/TabPage";
import "./Forum.scss";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "../Divider/Divider";
import RightComponent from "../RightComponent/RightComponent";
import axios from "../../utils/Axios";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { TooltipTheme, homeDialogTheme } from "../../utils/MuiThemes";
import { FormatTime } from "../../utils/TimeFormatter";
import { baseUrl } from "../../utils/Base";
import { useForm } from "react-hook-form";
import qs from "qs";
import ReCAPTCHA from "react-google-recaptcha";

function Forum() {
  const { forumState, forumDispatch } = useContext(ForumContext);
  const { state, dispatch } = useContext(Context);
  const checkRef = useRef();
  const tabContainerRef = useRef();
  const recaptchaRef = useRef(null);
  const actionContainerTopicRef = useRef();
  const actionTopicMenuRef = useRef();
  const [openDeleteForum, setOpenDeleteForum] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [start, setStart] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { loading, currentTabName, currentTabId, openAuthModal, userInfo } =
    state;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    load,
    errorPage,
    descData,
    showModal,
    followStatus,
    forumDetails,
    showForumAction,
    isTabDirector,
  } = forumState;
  const { token } = useParams();

  const { t } = useTranslation(["components", "extras"]);

  //Fetch data as component loads
  const forumUrl = `/f/${token}?cntr=${load}`;
  const { status, msg } = GetForumData(load, forumDispatch, forumUrl);
  const [position, setPosition] = useState({});

  const checkContainerPosition = useCallback(() => {
    setPosition(checkRef.current.getBoundingClientRect());
  }, []);

  useEffect(() => {
    const div = tabContainerRef.current;
    div.addEventListener("scroll", checkContainerPosition);
  }, [checkContainerPosition]);

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  useEffect(() => {
    if (status === "true") {
      enqueueSnackbar("You joined this Tab’s ongoing Forum", {
        variant: "success",
      });
    }

    if (status === "false") {
      enqueueSnackbar(msg !== null ? msg : "Token not valid!", {
        variant: "error",
      });
    }
  }, [status]);

  const handleCloseModal = () => {
    forumDispatch({ type: "HIDE_MODAL" });
  };

  const followFunc = async (tabName) => {
    const { data } = await axios.get(`/follow?tab=${tabName}`, config);
    if (data.msg === "Now following.") {
      dispatch({ type: "FOLLOWING", payload: data.msg });
      forumDispatch({
        type: "SET_FOLLOW_STATUS",
        payload: "unfollow",
      });
    } else if (data.msg === "Unfollowed.") {
      dispatch({ type: "NOT_FOLLOWING", payload: data.msg });
      forumDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
    }

    enqueueSnackbar(data?.msg, {
      variant: data?.status === true ? "success" : "error",
    });
  };

  useClickOut({
    onState: showForumAction,
    mainRef: actionTopicMenuRef,
    subRef: actionContainerTopicRef,
    dispatch: forumDispatch,
    dispatchType: "HIDE_FORUM_ACTION",
  });

  //Leave Forum

  const resignFunc = () => {
    enqueueSnackbar("You've left Forum", { variant: "success" });
    forumDispatch({ type: "HIDE_MODAL" });
    navigate("/");
  };

  const handleDeleteForum = async () => {
    try {
      const { data } = await axios.get(
        `/endforum?tab=${currentTabName}`,
        config
      );
      if (data?.status) {
        enqueueSnackbar("Forum ended", {
          variant: "success",
        });
        setOpenDeleteForum(false);
        dispatch({ type: "SET_FORUM_ACTION" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTabDirectorStatus = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const { data } = await axios.get(`/dxr?tab=${currentTabName}`, config);
    forumDispatch({ type: "SET_TAB_DIRECTOR", payload: data?.status });
  };

  useEffect(() => {
    fetchTabDirectorStatus();
  }, [userInfo]);

  useEffect(() => {
    dispatch({
      type: "SET_LAST_VISITED_FORUM_TOKEN",
      payload: null,
    });
  }, []);

  const showPassword = () => {
    setShow(!show);
  };

  const registerHandler = async ({ name, email, password, confirm }) => {
    const recaptcha = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    const dataSubmit = { name, email, password, confirm, recaptcha };
    const url = "/u";
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(dataSubmit),
      url,
    };
    if (password !== confirm) {
      enqueueSnackbar("Password and confirm password don't match", {
        variant: "error",
      });
      return;
    }
    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data?.tkn === "") {
        enqueueSnackbar(data?.msg, { variant: "error" });
        return;
      }
      if (data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        dispatch({ type: "CLOSE_AUTH_MODAL" });
        setCurrentPage(0);
        dispatch({
          type: "SET_LAST_VISITED_FORUM_TOKEN",
          payload: location.pathname.split("/")[2],
        });
        navigate("/confirmation");
        dispatch({ type: "NEW_USER_EMAIL", payload: email });
      } else {
        enqueueSnackbar(data?.msg, {
          variant: data?.status === true ? "success" : "error",
        });
        return;
      }
    } catch (err) {
      setStart(false);

      if (err?.code?.includes("NETWORK")) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
      }
      console.log(err);
    }
  };

  const loginHandler = async (data) => {
    const url = "/u";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      url,
    };
    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        dispatch({
          type: "SET_LAST_VISITED_FORUM_TOKEN",
          payload: location.pathname.split("/")[2],
        });
        dispatch({ type: "CLOSE_AUTH_MODAL" });
        setCurrentPage(0);
        navigate("/confirmation");
      }
      if (data?.tkn === "") {
        enqueueSnackbar(data?.msg, { variant: "error" });
        return;
      }
      if (data?.status === true && !data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        localStorage.setItem("userInfo", JSON.stringify(data?.usr));
        localStorage.setItem("lastSeen", data?.usr?.timelast);
        dispatch({ type: "USER_LOGIN", payload: data?.usr });
        dispatch({ type: "CLOSE_AUTH_MODAL" });
        setCurrentPage(0);
      }
    } catch (err) {
      setStart(false);

      if (err?.code?.includes("NETWORK")) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
      }
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) {
      navigate(`/forums/${token}`);
    }
  }, []);

  GetVerified();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Layout
          title={`${
            descData?.tab_desc?.title === undefined
              ? ""
              : descData?.tab_desc?.title
          } (@${
            descData?.tab_desc?.name === undefined
              ? ""
              : descData?.tab_desc?.name
          }) on TruTab`}
          description="Trutab&aposs forum"
        >
          <div className="left">
            <div className="withPadding">
              <HomeLeftBox />
            </div>

            <div
              className="footer"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Footer />
            </div>
          </div>

          <div className="middle">
            <div
              className="tabContainer"
              ref={tabContainerRef}
              style={{ paddingBottom: "50px" }}
            >
              <>
                {errorPage ? (
                  <ErrorPage />
                ) : (
                  <>
                    {(currentTabName !== null || currentTabId !== null) && (
                      <TabPage />
                    )}

                    <>
                      <div className="tpNavCont">
                        <nav
                          className="tpNav"
                          style={{ zIndex: currentTabName !== null && 0 }}
                        >
                          <IconButton
                            className="clickable"
                            style={{
                              display:
                                position?.top > 58 ||
                                position?.top === undefined
                                  ? "flex"
                                  : "none",
                            }}
                            onClick={() => navigate("/")}
                          >
                            <img
                              src="/assets/images/arrowBack.png"
                              alt=""
                              className="tpNavArrowBack"
                            />
                          </IconButton>
                          <div
                            className="tpNavTitle"
                            style={{
                              display:
                                position?.top > 58 ||
                                position?.top === undefined
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {descData?.tab_desc?.title}
                          </div>
                        </nav>
                      </div>

                      {descData?.tab_desc?.img !== null && (
                        <div className="tpBodyImg">
                          {descData?.tab_desc?.img === "" ? (
                            <img
                              src="/assets/images/newTabl.svg"
                              alt="attachment"
                              className="defaultImg"
                            />
                          ) : (
                            <img
                              src={`https://storage.googleapis.com/trutab/imgs/${descData?.tab_desc?.img}`}
                              alt=""
                              className="tabImg"
                            />
                          )}
                        </div>
                      )}

                      <div className="tpNameMemDirNoImg">
                        <div className="tpUniqueNameNoImg">
                          @{descData?.tab_desc?.name}
                        </div>
                        {descData?.membs_dirs.length === 0 ? (
                          ""
                        ) : (
                          <div className="tpMemberDirNoImg">
                            {descData?.membs_dirs[0]}{" "}
                            {descData?.membs_dirs[0] > 1
                              ? t("components:Members")
                              : t("components:Member")}{" "}
                            | {descData?.membs_dirs[1]}{" "}
                            {descData?.membs_dirs[1] > 1
                              ? t("components:Directors")
                              : t("components:Director")}
                          </div>
                        )}
                      </div>

                      <div className="tpDescription">
                        {descData?.tab_desc?.desc}
                      </div>
                      <div
                        className={
                          position?.top < 58
                            ? "tpLeaveTopicCommentChangePosition"
                            : "tpLeaveTopicComment"
                        }
                        ref={checkRef}
                      >
                        <IconButton
                          className="clickable"
                          style={{
                            display: position?.top < 58 ? "flex" : "none",
                          }}
                          onClick={() => navigate("/")}
                        >
                          <img
                            src="/assets/images/arrowBack.png"
                            alt=""
                            className="tpNavArrowBack"
                          />
                        </IconButton>
                        <div
                          style={{
                            display: position?.top < 58 ? "flex" : "none",
                            alignItems: "center",
                          }}
                        >
                          <div className="tpNavTitleChangePosition">
                            {descData?.tab_desc?.tab_title}
                          </div>

                          <div className="tpNavTitle">
                            {descData?.tab_desc?.title}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            forumDispatch({ type: "SHOW_MODAL" });
                          }}
                          className="tpLeave"
                          style={{
                            display:
                              position?.top > 58 || position?.top === undefined
                                ? "flex"
                                : "none",
                          }}
                        >
                          {(position?.top > 58 ||
                            position?.top === undefined) && (
                            <>
                              <Tooltip title="Leave Participation" arrow>
                                <span className="btn_participation">
                                  <img
                                    src="/assets/images/leave.svg"
                                    alt="leave"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                  {t("extras:btnLeave")}
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>

                        <div className="tpSettingTopic">
                          {position?.top < 49 && (
                            <img
                              src="/assets/images/forumWhite.svg"
                              alt="tab settings"
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}

                          <div
                            onClick={() => {
                              forumDispatch({ type: "SHOW_MODAL" });
                            }}
                            className="tpLeave"
                            style={{
                              display: position?.top < 49 ? "flex" : "none",
                            }}
                          >
                            <img
                              src={`/assets/images/${
                                position?.top < 49 ? "leaveWhite" : "leave"
                              }.svg`}
                              alt="leave"
                              style={{ width: "20px", height: "20px" }}
                            />
                            {(position?.top > 58 ||
                              position?.top === undefined) &&
                              t("extras:btnLeave")}
                          </div>
                        </div>
                      </div>
                      {forumDetails?.forum_start !== null && (
                        <section style={{ position: "relative" }}>
                          <div className="tpDivider">
                            <Divider
                              width="calc(100% - 16px)"
                              color="#908fc6"
                            />
                          </div>
                          <div className="tpForumTimeDescAction">
                            <div className="tpForumTimeDesc">
                              <div className="tpForumTime">
                                <img src="/assets/images/forum.svg" alt="" />
                                <span>{FormatTime(forumDetails?.start)}</span>
                              </div>
                              {forumDetails?.forum_desc}
                            </div>
                            {(descData?.usr_id ===
                              descData?.tab_desc?.usr_id?.toString() ||
                              isTabDirector) && (
                              <img
                                src="/assets/images/action.png"
                                alt=""
                                onClick={() =>
                                  forumDispatch({ type: "SHOW_FORUM_ACTION" })
                                }
                                ref={actionTopicMenuRef}
                              />
                            )}
                          </div>

                          <div
                            className={
                              showForumAction
                                ? "actionContainer"
                                : "actionContainer hideAction"
                            }
                            ref={actionContainerTopicRef}
                          >
                            <ThemeProvider theme={TooltipTheme}>
                              <Tooltip title="Download QR image" arrow>
                                <a
                                  href={`${baseUrl}/trutab_qrcode?q=${baseUrl}/forums/${
                                    location.pathname.split("/")[2]
                                  }&f=png`}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src="/assets/images/qr.svg" alt="" />
                                </a>
                              </Tooltip>
                            </ThemeProvider>

                            {(descData?.usr_id ===
                              descData?.tab_desc?.usr_id?.toString() &&
                              location.pathname.split("/")[1] !== "forums") ||
                              (isTabDirector &&
                                location.pathname.split("/")[1] !==
                                  "forums" && (
                                  <img
                                    src="/assets/images/recycle.svg"
                                    alt=""
                                    className="icon"
                                    onClick={() => setOpenDeleteForum(true)}
                                  />
                                ))}

                            <ThemeProvider theme={homeDialogTheme}>
                              <Dialog
                                open={openDeleteForum}
                                onClose={() => setOpenDeleteForum(false)}
                                maxWidth="xs"
                                PaperProps={{
                                  style: {
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                <div
                                  className="tpDialogContainer"
                                  style={{ backgroundColor: "#f8fcf8" }}
                                >
                                  <p>{t("components:sureEndForum")}</p>

                                  <div className="btn">
                                    <button
                                      onClick={() => setOpenDeleteForum(false)}
                                      className="btnCancel"
                                    >
                                      {t("extras:btnCancel")}
                                    </button>
                                    <button
                                      onClick={handleDeleteForum}
                                      className="btnExecute"
                                    >
                                      {t("extras:btnDelete")}
                                    </button>
                                  </div>
                                </div>
                              </Dialog>
                            </ThemeProvider>
                          </div>
                        </section>
                      )}

                      <div className="tpDivider">
                        <Divider width="calc(100% - 16px)" color="#908fc6" />
                      </div>

                      <div className="tpBody">
                        <div className="tpTopicNum">
                          <div>
                            {descData?.total === 0 ? "" : descData?.total}{" "}
                            {descData?.total < 1
                              ? t("components:noTopic")
                              : descData?.total === 1
                              ? t("components:topic")
                              : t("components:topics")}
                          </div>{" "}
                          {descData?.total_public !== 0 && (
                            <div>
                              {descData?.total_public} {t("components:public")}
                            </div>
                          )}
                          {descData?.membs_dirs.length === 0 ? (
                            <button
                              onClick={() =>
                                followFunc(descData?.tab_desc?.name)
                              }
                            >
                              {followStatus === "unfollow"
                                ? t("components:unfollow")
                                : t("components:follow")}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <Dialog
                        open={showModal}
                        onClose={handleCloseModal}
                        maxWidth="xs"
                      >
                        <div style={{ padding: "30px", background: "#f8f5fe" }}>
                          <DialogTitle id="responsive-dialog-title">
                            <Typography>
                              {" "}
                              {t("components:leaveForum")}
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              <Typography>
                                {t("components:sureLeaveForum")}
                              </Typography>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <div className="tpBtnCont">
                              <Button onClick={resignFunc}>
                                {t("components:leave")}
                              </Button>
                              <Button
                                onClick={() => {
                                  forumDispatch({ type: "HIDE_MODAL" });
                                }}
                              >
                                {t("components:noLeave")}
                              </Button>
                            </div>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </>
                  </>
                )}
              </>
              <Topics localState={forumState} localDispatch={forumDispatch} />
              <Modal
                open={openAuthModal}
                onClose={() => {
                  dispatch({ type: "CLOSE_AUTH_MODAL" });
                  setCurrentPage(0);
                }}
              >
                <section className="authModalContainer">
                  {currentPage === 0 && (
                    <>
                      <h4>{t("components:pleaseLogin")}</h4>

                      <button
                        className="btnAuth"
                        onClick={() => setCurrentPage(1)}
                      >
                        {t("extras:loginSignUp")}
                      </button>
                    </>
                  )}

                  {currentPage === 1 && (
                    <>
                      <p style={{ textAlign: "center" }}>
                        {t("components:createAcc")}
                      </p>
                      <form
                        onSubmit={handleSubmit(registerHandler)}
                        autoComplete="on"
                      >
                        <p className="regDescription">
                          {t("components:emailSlogan")}
                        </p>
                        <div className="regInputContainer">
                          <input
                            placeholder={t("components:email")}
                            {...register("email", {
                              required: true,
                              pattern:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            })}
                          />
                          <img src="/assets/images/Email.svg" alt="" />
                        </div>

                        {(errors?.email ||
                          errors?.email?.type === "pattern") && (
                          <p className="regError">
                            {errors?.email
                              ? errors?.email?.type === "pattern"
                                ? t("components:errEmailNotValid")
                                : t("components:errEmailRequired")
                              : ""}
                          </p>
                        )}

                        <div className="regInputContainer">
                          <input
                            placeholder={t("components:slogan")}
                            {...register("name", {
                              required: true,
                              minLength: 4,
                              maxLength: 80,
                            })}
                          />
                        </div>

                        {errors?.name && (
                          <p className="regError">
                            {t("components:errEnterName")}
                          </p>
                        )}
                        <p className="regDescription">
                          {t("components:password")}
                        </p>
                        <div className="regInputContainer">
                          <input
                            placeholder={t("components:password")}
                            type={show ? "text" : "password"}
                            {...register("password", { required: true })}
                          />
                          <img
                            src={
                              show
                                ? "/assets/images/Eye.svg"
                                : "/assets/images/Invisible.svg"
                            }
                            onClick={showPassword}
                            alt=""
                          />
                        </div>
                        {errors?.password && (
                          <p className="regError">
                            {t("components:errEnterPass")}
                          </p>
                        )}

                        <div className="regInputContainer">
                          <input
                            placeholder={t("components:confirmPassI")}
                            type={show ? "text" : "password"}
                            {...register("confirm", { required: true })}
                          />
                          <img
                            src={
                              show
                                ? "/assets/images/Eye.svg"
                                : "/assets/images/Invisible.svg"
                            }
                            onClick={showPassword}
                            alt=""
                          />
                        </div>
                        {errors?.confirm && (
                          <p className="regError">
                            {t("components:errConfirmPass")}
                          </p>
                        )}
                        <div className="ReCAPTCHA">
                          <ReCAPTCHA
                            sitekey="6LduiQkcAAAAAH7gJkFAfVY4FS0fe3538vjtk-8z"
                            ref={recaptchaRef}
                          />
                        </div>
                        <button type="submit" className="regBtn">
                          {t("extras:btnSignUp")}
                          {start && (
                            <CircularProgress
                              size={15}
                              className="btnProgress"
                            />
                          )}
                        </button>
                        <div className="viewPublic">
                          <p> {t("components:alreadyHaveAccount")}</p>
                          <button
                            className="frmBtnLogin"
                            onClick={() => setCurrentPage(2)}
                          >
                            {t("components:login")}
                          </button>
                        </div>
                      </form>
                    </>
                  )}

                  {currentPage === 2 && (
                    <>
                      <p style={{ textAlign: "center" }}>
                        {t("components:login")}
                      </p>
                      <form onSubmit={handleSubmit(loginHandler)}>
                        <div className="lgInputContainer">
                          <input
                            placeholder={t("components:email")}
                            {...register("emailLg", {
                              required: true,
                              pattern:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            })}
                          />
                          <img src="/assets/images/Email.svg" alt="" />
                        </div>

                        {(errors?.emailLg ||
                          errors?.emailLg?.type === "pattern") && (
                          <p className="lgError">
                            {errors?.emailLg
                              ? errors?.emailLg?.type === "pattern"
                                ? t("components:errEmailNotValid")
                                : t("components:errEmailRequired")
                              : ""}
                          </p>
                        )}

                        <div className="lgInputContainer">
                          <input
                            placeholder={t("components:password")}
                            type={show ? "text" : "password"}
                            {...register("passwordLg", { required: true })}
                          />
                          <img
                            src={
                              show
                                ? "/assets/images/Invisible.svg"
                                : "/assets/images/Eye.svg"
                            }
                            onClick={showPassword}
                            alt=""
                          />
                        </div>
                        {errors?.passwordLg && (
                          <p className="lgError">
                            {t("components:errPassRequired")}
                          </p>
                        )}
                        <div className="lgForgetPass">
                          <p>
                            <a href={`${baseUrl}/recovery`}>
                              {t("components:forget")}
                            </a>
                          </p>
                        </div>

                        <button type="submit" className="lgLoginBtn">
                          {t("components:login")}
                          {start && (
                            <CircularProgress
                              size={15}
                              className="btnProgress"
                            />
                          )}
                        </button>
                        <div className="viewPublic">
                          <p> {t("components:noAccount")}</p>
                          <button
                            className="frmBtnLogin"
                            onClick={() => setCurrentPage(1)}
                          >
                            {t("extras:btnSignUp")}
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </section>
              </Modal>
            </div>
          </div>

          <div className="right">
            <RightComponent />
          </div>
        </Layout>
      )}
    </>
  );
}

export default Forum;
